.videoTitle {
  position: absolute;
  left: 0;
  top: 0;
  padding: 1em;
  color: #aaa;
  font-size: smaller;
  font-weight: 500;
  content: 'EXAMPLE';
}

.videoDiv {
  border: 1px solid #d8d8d8;
  text-align: center;
}
